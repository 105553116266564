import React from "react";
import Img from "gatsby-image";

import Heading from "../theme/heading";

function About({ headshot, bio }) {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start container mb-24">
      <div className="md:hidden">
        <Heading centered>Meet your coach</Heading>
      </div>
      <div className="flex-1 relative">
        <div className="absolute top-0 left-0 bottom-4 right-4 md:bottom-8 md:right-8 bg-teal-300 rounded shadow" />
        <Img fluid={headshot.fluid} className="rounded mt-4 ml-4 md:mt-8 md:ml-8 min-w-56" alt="Sara" />
      </div>
      <div className="md:w-1/2 md:ml-16 lg:ml-32 text-lg leading-loose space-y-6 max-w-xl md:max-w-full">
        <div className="hidden md:block"><Heading>Meet your coach</Heading></div>
         <div className="space-y-6" dangerouslySetInnerHTML={{ __html:
           bio.childMarkdownRemark.html
         }}/>
      </div>
    </div>
  );
}

export default About;
