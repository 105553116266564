import React, { useState } from "react";

import Heading from "../theme/heading";
import ProgramIcon from "./program-icon";
import ProgramPicker from "./program-picker";
import Health from "../../images/health.inline.svg";
import Career from "../../images/career.inline.svg";
import Leadership from "../../images/leadership.inline.svg";

function Program({ title, image, children }) {
  return (
    <div className="bg-white rounded shadow flex-grow p-8 max-w-xl">
      <ProgramIcon image={image} className="hidden lg:block mx-auto mb-4 w-32" />
      <h3 className="font-display uppercase font-bold text-center text-teal-500 text-2xl mb-4">
        {title}
      </h3>
      <div className="xl:text-lg leading-loose space-y-6">
        {children}
      </div>
    </div>
  );
}

function Programs({
  healthProgramDescription,
  careerProgramDescription,
  leadershipProgramDescription
}) {
  const [ program, setProgram ] = useState('health');

  return (
    <div className="container">
      <Heading centered>Programs</Heading>
      <div className="flex flex-col lg:flex-row items-center lg:items-start">
        <ProgramPicker selected={program} onSelect={setProgram} />
        { program === "health" && (
          <Program title="Health" image={Health}>
            <div className="space-y-6" dangerouslySetInnerHTML={{ __html:
              healthProgramDescription.childMarkdownRemark.html
            }}/>
          </Program>
        )}
        { program === "career" && (
          <Program title="Career" image={Career}>
            <div className="space-y-6" dangerouslySetInnerHTML={{ __html:
              careerProgramDescription.childMarkdownRemark.html
            }}/>
          </Program>
        )}
        { program === "leadership" && (
          <Program title="Leadership" image={Leadership}>
            <div className="space-y-6" dangerouslySetInnerHTML={{ __html:
              leadershipProgramDescription.childMarkdownRemark.html
            }}/>
          </Program>
        )}
      </div>
    </div>
  );
}

export default Programs;
