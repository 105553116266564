import React from "react";
import Img from "gatsby-image";

function Testimonial({ image, quote, name }) {
  return (
    <div className="relative border-2 border-solid border-teal-500 rounded px-8 pt-20 pb-8 max-w-sm md:w-1/3 mx-auto mx-4 mt-12 leading-relaxed mb-16 md:mb-0 shadow">
      <div className="absolute text-teal-300 inset-0 -mt-10 ml-4 lg:ml-6 leading-none" style={{ fontSize: '180px' }}>&ldquo;</div>
      <div className="absolute top-0 left-0 w-full flex justify-center -mt-12">
        <Img fixed={image.fixed} className="w-24 h-24 rounded-full shadow" />
      </div>
      <p className="text-left">{quote}</p>
      { name && (
        <div className="text-right mt-4 text-teal-500 font-bold">~ {name}</div>
      )}
    </div>
  );
}

function Testimonials({ content }) {
  return(
    <div className="container">
      <div className="flex flex-col md:flex-row md:-mx-4">
        { content.map(({ quote, name, headshot }) => (
          <Testimonial
            key={name}
            image={headshot}
            quote={quote}
            name={name}
          />
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
