/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function ProgramIcon({ image: Image, className, onClick }) {
  return (
    <div
      className={classnames(
        "bg-white rounded-full shadow border-solid border-teal-500 border-2",
        { "cursor-pointer": onClick },
        className
      )}
      onClick={onClick}
    >
      <div className="h-0 pt-full flex flex-col justify-center relative">
        <Image className="absolute" width="70%" height="70%" style={{ top: "15%", left: "15%" }} />
      </div>
    </div>
  );
}

ProgramIcon.propTypes = {
  image: PropTypes.any.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

ProgramIcon.defaultProps = {
  className: null,
  style: {},
  size: 230,
  onClick: null,
}

export default ProgramIcon;
