import React from "react";
import Img from "gatsby-image";

function HowItWorksCard({ image, title, description }) {
  return (
    <div className="bg-white rounded shadow md:overflow-hidden flex-1 flex flex-col items-center mb-12 md:mb-0 mx-6 mt-24 md:mt-0 max-w-sm">
      <div className="w-3/5 md:w-full max-w-xxs md:max-w-none rounded md:rounded-none overflow-hidden -mt-24 md:mt-0 shadow md:shadow-none">
        <Img fluid={image.fluid} alt={title} />
      </div>
      <div className="w-full xl:text-lg p-6 md:text-center">
        <h2 className="font-bold mb-2 md:mb-4 xl:text-xl text-center">{title}</h2>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
}

export default HowItWorksCard;
