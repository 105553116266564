import React from "react";

import Heading from "../theme/heading";
import HowItWorksCard from "./how-it-works-card";

function HowItWorks({ content }) {
  return (
    <div className="container">
      <Heading centered>How it works</Heading>
      <div className="-mx-6 flex flex-col items-center md:items-stretch md:flex-row">
        { content.map(({ title, description, image })=> (
          <HowItWorksCard
            key={title}
            image={image}
            title={title}
            description={description}
          />
        ))}
      </div>
    </div>
  );
}

export default HowItWorks;
