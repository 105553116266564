import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HomeHero from "../components/index/home-hero";
import HowItWorks from "../components/index/how-it-works";
import Programs from "../components/index/programs";
import About from "../components/index/about";
import Testimonials from "../components/index/testimonials";
import RecentBlogArticles from "../components/theme/recent-blog-articles";
import CallToAction from "../components/theme/call-to-action";

function Index({ data: { contentfulHomePage } }) {
  const {
    title, subtitle, heroImage, howItWorks, healthProgramDescription, careerProgramDescription,
    leadershipProgramDescription, saraHeadshot, saraBio, testimonials
  } = contentfulHomePage;

  return (
    <Layout>
      <SEO title="Home" />
      <HomeHero title={title} subtitle={subtitle} heroImage={heroImage} />
      <section className="py-24">
        <HowItWorks content={howItWorks} />
      </section>
      <section className="py-24 bg-cool-gray-100">
        <Programs
          healthProgramDescription={healthProgramDescription}
          careerProgramDescription={careerProgramDescription}
          leadershipProgramDescription={leadershipProgramDescription}
        />
      </section>
      <CallToAction />
      <section className="py-24">
        <About headshot={saraHeadshot} bio={saraBio} />
        <Testimonials content={testimonials} />
      </section>
      <section className="py-24 bg-cool-gray-100">
        <RecentBlogArticles heading="From the blog" />
      </section>
      <CallToAction />
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query HomePage {
    contentfulHomePage {
      title
      subtitle
      heroImage {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      howItWorks {
        title
        description
        image {
          fluid(maxWidth: 350) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      healthProgramDescription {
        childMarkdownRemark {
          html
        }
      }
      careerProgramDescription {
        childMarkdownRemark {
          html
        }
      }
      leadershipProgramDescription {
        childMarkdownRemark {
          html
        }
      }
      saraHeadshot {
        fluid(maxWidth: 448, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      saraBio {
        childMarkdownRemark {
          html
        }
      }
      testimonials {
        quote
        name
        headshot {
          fixed(width: 96, height: 96) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`
