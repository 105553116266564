import React from "react";
import classnames from "classnames";

import ProgramIcon from "./program-icon";
import Health from "../../images/health.inline.svg";
import Career from "../../images/career.inline.svg";
import Leadership from "../../images/leadership.inline.svg";

function ProgramPicker({ selected, onSelect }) {

  const Program = ({ type, image }) => (
    <ProgramIcon
      image={image}
      className={classnames(
        "w-1/3 md:w-48 xl:w-56 mx-2 sm:mx-4 lg:mx-0",
        selected === type ? "border-6" : "border-2"
      )}
      onClick={() => onSelect(type)}
    />
  );

  const health = (<Program type="health" image={Health} />);
  const career = (<Program type="career" image={Career} />);
  const leadership = (<Program type="leadership" image={Leadership} />);

  return (
    <>
      <div className="lg:hidden w-full flex justify-around mb-8">
        { health }
        { career }
        { leadership }
      </div>
      <div className="hidden lg:block relative pt-20 pl-20 pr-20 pb-10 xl:pt-24 xl:pl-24 xl:pr-24 xl:pb-12 mr-24">
        <div className="w-80 h-80 xl:w-96 xl:h-96 bg-teal-300 border-2 border-solid border-teal-500 rounded-full text-center text-white pt-32 xl:pt-40 text-3xl leading-loose">
          elevate
        </div>
        <div className="absolute flex justify-center top-0 left-0 w-full">
          { health }
        </div>
        <div className="absolute flex justify-between bottom-0 left-0 w-full">
          { career }
          { leadership }
        </div>
      </div>
    </>
  );
}

export default ProgramPicker;
