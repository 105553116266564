import React from "react";

import Hero from "../theme/hero";

// import Fog from "../../videos/fog.mp4";

function HomeHero({ title, subtitle, heroImage, useVideo }) {
  const content = (
    <div className="relative container pt-64 md:pt-48 pb-32 text-center text-white">
      <h1 className="text-4xl md:text-6xl font-display font-bold">{ title }</h1>
      <p className="text-lg">{ subtitle }</p>
    </div>
  );

  // if (useVideo) {
  //   return (
  //     <div style={{ minHeight: '100vh' }}>
  //       <div className="absolute overflow-hidden w-full" style={{ minHeight: '100vh' }}>
  //         <video autoplay="autoplay" loop="loop" muted="muted" className="absolute min-w-full max-w-none h-full" style={{ width: '177.77777778vh', minHeight: '56.25vw', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
  //           <source src={Fog} type="video/mp4" />
  //         </video>
  //       </div>
  //       <div className="absolute bg-black opacity-25 top-0 left-0 w-full h-full"></div>
  //       { content }
  //     </div>
  //   );
  // }
 
  return (
    <Hero
      imageFile={heroImage}
      overlay="linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(255,255,255,0))"
      screen
    >
      { content }
    </Hero>
  );
}

export default HomeHero;
